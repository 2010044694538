import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { type UseMutationOptions } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import {
  AssessmentNotificationReason,
  type AssessmentNotificationRequestResponse,
  useAssessmentNotificationRequest,
} from "./useAssessmentNotificationRequest";

export const assessmentConfirmedNotificationIssueParamsSchema = z.object({
  assessmentId: z.string(),
});

export type AssessmentConfirmedNotificationIssueParams = z.infer<
  typeof assessmentConfirmedNotificationIssueParamsSchema
>;

export function useAssessmentConfirmedNotificationIssue(
  options: UseMutationOptions<
    AssessmentNotificationRequestResponse,
    AxiosError,
    AssessmentConfirmedNotificationIssueParams
  > = {}
) {
  const mutation = useAssessmentNotificationRequest({
    onError: (error, variables) => {
      logEvent(APP_V2_APP_EVENTS.SKILLS_ASSESSMENTS_CONFIRMED_NOTIFICATION_ISSUE_FAILURE, {
        error,
        variables,
      });
    },
    ...options,
  });

  return {
    ...mutation,
    mutateAsync: async (params: AssessmentConfirmedNotificationIssueParams) => {
      return await mutation.mutateAsync({
        ...params,
        reason: AssessmentNotificationReason.ASSESSMENT_CONFIRMED_NOTIFICATION_ISSUE,
      });
    },
  };
}
