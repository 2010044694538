import { post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

export function assessmentNotificationRequestApiUrl(assessmentId: string) {
  return `${environmentConfig.REACT_APP_BASE_API_URL}/assessments/${assessmentId}/notification-requests`;
}

export enum AssessmentNotificationReason {
  ASSESSMENT_CONFIRMED_NOTIFICATION_ISSUE = "ASSESSMENT_CONFIRMED_NOTIFICATION_ISSUE",
}

export const assessmentNotificationRequestSchema = z.object({
  data: z.object({
    type: z.literal("assessment-notification-request"),
    attributes: z.object({
      reason: z.enum(
        Object.values(AssessmentNotificationReason) as [
          AssessmentNotificationReason,
          ...AssessmentNotificationReason[]
        ]
      ),
    }),
  }),
});

export type AssessmentNotificationRequest = z.infer<typeof assessmentNotificationRequestSchema>;

export const assessmentNotificationRequestParamsSchema = z.object({
  assessmentId: z.string(),
  reason: z.enum(
    Object.values(AssessmentNotificationReason) as [
      AssessmentNotificationReason,
      ...AssessmentNotificationReason[]
    ]
  ),
});

export type AssessmentNotificationRequestParams = z.infer<
  typeof assessmentNotificationRequestParamsSchema
>;

export const assessmentNotificationRequestResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    type: z.literal("assessment-notification-request"),
  }),
});

export type AssessmentNotificationRequestResponse = z.infer<
  typeof assessmentNotificationRequestResponseSchema
>;

export function useAssessmentNotificationRequest(
  options: UseMutationOptions<
    AssessmentNotificationRequestResponse,
    AxiosError,
    AssessmentNotificationRequestParams
  > = {}
) {
  return useMutation({
    mutationFn: async (params) => {
      const { assessmentId, ...rest } = params;

      const response = await post({
        url: assessmentNotificationRequestApiUrl(assessmentId),
        data: {
          data: {
            type: "assessment-notification-request",
            attributes: rest,
          },
        },
        requestSchema: assessmentNotificationRequestSchema,
        responseSchema: assessmentNotificationRequestResponseSchema,
      });
      return response.data;
    },
    onError(error, variables) {
      logEvent(APP_V2_APP_EVENTS.SKILLS_ASSESSMENTS_NOTIFICATION_REQUEST_FAILURE, {
        error,
        variables,
      });
    },
    ...options,
  });
}
