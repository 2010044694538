import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

export function useSkillsAssessmentFeatureFlags() {
  const featureFlagValue = useCbhFlag(CbhFeatureFlag.ROLLOUT_SKILLS_ASSESSMENT, {
    defaultValue: { isEnabled: false },
  });
  const isSkillsAssessmentOnboardingEnabled = useCbhFlag(
    CbhFeatureFlag.ROLLOUT_SKILLS_ASSESSMENT_DURING_ONBOARDING,
    {
      defaultValue: false,
    }
  );
  const skillsAssessmentConfig = useCbhFlag(CbhFeatureFlag.SKILLS_ASSESSMENTS_CONFIG, {
    defaultValue: {
      cooldownTimeAfterFailureInDays: 7,
      availableQualificationNames: ["CNA"],
      maxNumberOfVerifiedShiftsAllowedToBeEligible: 15,
      passPercentage: 0.5,
      supportArticleLink:
        "https://support.clipboardhealth.com/hc/en-us/articles/28270660195735-What-is-the-Skills-Assessment",
    },
  });
  const scheduledAssessmentConfig = useCbhFlag(CbhFeatureFlag.SCHEDULED_ASSESSMENTS_CONFIG, {
    defaultValue: {
      allowedEarlinessInMinutes: 5,
      allowedLatenessInMinutes: 60,
      hoursUntilAutomaticalFailForBeingLate: 2,
      reminderSchedule: [],
      minimumLeadTimeToScheduleAssessmentInHours: 12,
      maximumLeadTimeToScheduleAssessmentInHours: 72,
      rescheduleLeadTimeInMinutes: 60,
      allowedTimeRange: {
        start: {
          hour: 7,
          minute: 0,
        },
        end: {
          hour: 23,
          minute: 0,
        },
      },
    },
  });

  return {
    isSkillsAssessmentEnabled: featureFlagValue.isEnabled,
    isSkillsAssessmentOnboardingEnabled,
    skillsAssessmentConfig,
    scheduledAssessmentConfig,
  };
}
