import { isDefined } from "@clipboard-health/util-ts";
import { logEvent } from "@src/appV2/lib/analytics";
import { type CbhAppInfo, isCapacitorPlatform } from "@src/appV2/lib/index";
import { USER_EVENTS } from "@src/constants";

import {
  type HcfTimeclockComplianceRollout,
  HcfTimeclockComplianceRolloutState,
} from "../Shift/ShiftState/types";
import { isEnabledForAppVersionAndOta } from "./isSolveUnpaidBreaksFeatureEnabled";

interface IsHcfTimeclockComplianceEnabled {
  hcfTimeclockComplianceRollout?: HcfTimeclockComplianceRollout;
  appInfo?: CbhAppInfo;
}

export function checkIsHcfTimeclockComplianceEnabled(
  parameters: IsHcfTimeclockComplianceEnabled
): boolean {
  const { hcfTimeclockComplianceRollout, appInfo } = parameters;

  if (!isDefined(hcfTimeclockComplianceRollout)) {
    return false;
  }

  const { state, minimalOtaVersion, minimalStoreVersion } = hcfTimeclockComplianceRollout;

  if (state !== HcfTimeclockComplianceRolloutState.ENABLED) {
    return false;
  }

  // on web, no need to check version
  if (!isCapacitorPlatform()) {
    return true;
  }

  // if appInfo is not loaded, we can't check version
  if (!isDefined(appInfo)) {
    return false;
  }

  // if version is too low, return false
  try {
    if (
      !isEnabledForAppVersionAndOta({
        minimalOtaVersion: String(minimalOtaVersion),
        minimalStoreVersion,
        appInfo,
      })
    ) {
      return false;
    }
  } catch {
    logEvent(USER_EVENTS.SOLVE_UNPAID_BREAKS_VERSION_ERROR, {
      hcfTimeclockComplianceRollout,
    });
    return false;
  }

  return true;
}

export function checkIsHcfTimeclockComplianceEnabledWithVideo(
  parameters: IsHcfTimeclockComplianceEnabled
): boolean {
  const { hcfTimeclockComplianceRollout, appInfo } = parameters;

  if (!isDefined(hcfTimeclockComplianceRollout)) {
    return false;
  }

  const { state, videoMinimalVersion, useVideo } = hcfTimeclockComplianceRollout;

  // if feature is not enabled, return false
  if (state !== HcfTimeclockComplianceRolloutState.ENABLED || !useVideo) {
    return false;
  }

  // on web, no need to check version
  if (!isDefined(videoMinimalVersion) || !isCapacitorPlatform()) {
    return true;
  }

  // if appInfo is not loaded, we can't check version
  if (!isDefined(appInfo)) {
    return false;
  }

  // if version is too low, return false
  try {
    if (
      !isEnabledForAppVersionAndOta({
        minimalOtaVersion: String(videoMinimalVersion.ota),
        minimalStoreVersion: videoMinimalVersion.store,
        appInfo,
      })
    ) {
      return false;
    }
  } catch {
    logEvent(USER_EVENTS.SOLVE_UNPAID_BREAKS_VERSION_ERROR, {
      hcfTimeclockComplianceRollout,
    });
    return false;
  }

  return true;
}
